<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()" :disabled="isSaving" type="button"
						class="btn btn-info mt-3 float-left">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right" to="/admin/Setting/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="setting_key" class="col-sm-2 col-form-label">鍵值</label>
							<div class="col-sm-10">
								<input v-model="model.setting_key" type="text" class="form-control" id="setting_key"
									name="setting_key" required readonly />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="setting_name" class="col-sm-2 col-form-label">名稱</label>
							<div class="col-sm-10">
								<input v-model="model.setting_name" type="text" class="form-control" id="setting_name"
									name="setting_name" required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="setting_type" class="col-sm-2 col-form-label">分類</label>
							<div class="col-sm-10">
								<input v-model="model.setting_type" type="text" class="form-control" id="setting_type"
									name="setting_type" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="setting_value" class="col-sm-2 col-form-label">值</label>
							<div class="col-sm-10">
								<!-- 動態顯示不同的輸入框 -->
								<input v-if="model.value_type === 'text'" v-model="model.setting_value" type="text" class="form-control" id="setting_value" name="setting_value" />
								<textarea v-else-if="model.value_type === 'textarea'" v-model="model.setting_value" class="form-control" id="setting_value" name="setting_value"></textarea>
								<div v-else-if="model.value_type === 'checkbox'" class="form-check">
									<input v-model="model.setting_value" type="checkbox" class="form-check-input" id="setting_value" name="setting_value" :true-value="true" :false-value="false" />
									<label class="form-check-label" for="setting_value">是否啟用</label>
								</div>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="setting_describe" class="col-sm-2 col-form-label">說明</label>
							<div class="col-sm-10">
								<input v-model="model.setting_describe" type="text" class="form-control"
									id="setting_describe" name="setting_describe" />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="setting_sort" class="col-sm-2 col-form-label">排序</label>
							<div class="col-sm-10">
								<input v-model="model.setting_sort" type="number" class="form-control" id="setting_sort"
									name="setting_sort" />
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
import LayoutDiv from '@/components/LayoutDiv.vue';
import { getSetting, postSetting } from '@/api/Setting.js'
import toastr from 'toastr'

export default {
	name: 'SettingEdit',
	components: {
		LayoutDiv,
	},
	data() {
		return {
			model: {
				setting_key: '',
				setting_name: '',
				setting_type: 1,
				value_type: 'text',
				setting_value: '',
				setting_describe: '',
				setting_sort: 999,

			},
			isSaving: false,
		};
	},
	created() {
		const id = this.$route.params.id;

		getSetting(id).then(rep => {
			this.model = rep.data;
		});

	},
	methods: {
		checkFields() {
			let test = true;
			if (this.model.setting_key == "") test = this.pickupWarning("鍵值欄位必填");
			if (this.model.setting_name == "") test = this.pickupWarning("名稱欄位必填");
			if (this.model.setting_type == 100) test = this.pickupWarning("類別100是特殊用途, 不可使用");
			return test;
		},
		pickupWarning(message) {
			toastr.error(message);
			return false;
		},
		handleSave() {
			if (this.isSaving == true) return;
			this.isSaving = true;

			if (this.checkFields() == false) {
				this.isSaving = false
				return false;
			}
			postSetting(this.model).then(rep => {

				this.isSaving = false;
				return rep;
			})
				.catch(error => {
					this.isSaving = false;
					return error
				});
		},
	},
};
</script>